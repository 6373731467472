import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
      component: () => import('@/views/LandingPage.vue')
  },
  {
    path: '*',
    name: 'NotFound',
      component: () => import('@/views/NotFound.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
