<template>
  <v-app  class="content">
    <v-main>
      <div class="d-md-flex">
        <a href="https://www.brani.cz" target="_blank">
          <v-img
            alt="Logo"
            class="shrink"
            contain
            src="/brani-logo-white-center.png"
            height="64"/>
        </a>
        <v-spacer />
      </div>
      <router-view/>
      <div class="circle" />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
   #app {
    background: #f6f8fe;
    overflow: hidden;
  }

  @media only screen and (min-width: 821px) {
      .content:not(.no-padding) {
          padding: 1.5rem 2.5rem 2.5rem 1.5rem;
          padding-bottom: 0px !important;
      }
  }

  @media only screen and (max-width: 820px) {
      .content:not(.no-padding) {
        padding: 25px 1rem 0.5rem 1rem;
      }
  }

  .circle {
    background: #173753;
    border-radius: 50%;
    width: 100vw;
    height: 50vw;
    position: absolute;
    bottom: -40vw;
    left: -55vw;
    min-width: 300px;
  }
</style>
